@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#root {
  height: 100%;
  width: 100%;
}
th {
  font-weight: inherit;
}
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

@font-face {
  font-family: 'RockStar';
  src: url('../src/assets/fonts/RockStar.woff2') format('woff2');
  font-weight: 600;
}
@font-face {
  font-family: 'OnestRegular';
  src: url('../src/assets/fonts/OnestRegular.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'OnestMedium';
  src: url('../src/assets/fonts/OnestMedium.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'KoulenRegular';
  src: url('../src/assets/fonts/Koulen-Regular.ttf') format('ttf');
  font-weight: 600;
}

@layer base {
  h1,
  h2,
  h3,
  h4 {
    font-family: KoulenRegular;
  }
  input[type='number'] {
    @apply bg-inherit;
  }
  /* input[type='text']:focus {
    --tw-ring-color: rgb(62 62 62 / var(--tw-bg-opacity));
    border-color: rgb(62 62 62 / var(--tw-bg-opacity));
    --tw-ring-shadow: none;
  } */
}

@layer utilities {
  .bg-red-500 {
    @apply bg-main-red;
  }
  .text-red-500 {
    @apply text-main-red;
  }
  .bg-slate-800 {
    @apply bg-main-black;
  }
}

@layer components {
  .btn-select {
    @apply cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left  transition ease-in-out duration-150;
  }
}

*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 8px;
}

input:checked + div {
  @apply border-[#E72F3C];
}
input:checked + div svg {
  @apply block;
}

@keyframes slide-in {
  0% {
    right: -420px;
  }
  100% {
    right: 0;
  }
}

.time_picker_scrollbar::-webkit-scrollbar {
  width: 7px;
}

.time_picker_scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #a8a8a8;
  height: 40px;
  width: 6px;
}

.time_picker_scrollbar::-webkit-scrollbar-track {
  border-radius: 50px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.apexcharts-tooltip {
  color: rgb(15, 15, 15);
}